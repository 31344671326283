body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #797272;
}

h1, p {
  letter-spacing: 1px;
}

a {
  text-decoration: none;
  color: #797272;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 20px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  h1 {
    font-size: 30px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  h1 {
    font-size: 40px;
  }
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: 50px;
  }
}